import dynamic from 'next/dynamic';
import React from 'react';

import Header from '@/components/layouts/header';
import {
  COPYRIGHT,
  MENU_LCV_BUYING_TOOLS,
  MENU_LCV_MODEL_LCV_FOOTER,
  MENU_LCV_PRIMARY_NAV,
  MENU_LCV_SECONDARY_NAV,
  MENU_LCV_SOCIAL,
  MENU_LCV_TEST_DRIVE_FOOTER,
  REMARK,
} from '@/contents/lcv/link';

const Footer = dynamic(import('@/components/layouts/footer'), {
  ssr: false,
});

const FloatingMenu = dynamic(
  import('@/components/sections/my24/shared/floating-buying-tools'),
  {
    ssr: false,
  }
);
interface IProps {
  children?: React.ReactNode;
}

const View: React.FunctionComponent<IProps> = (props: IProps) => {
  return (
    <>
      <Header
        menuBuyingTool={MENU_LCV_BUYING_TOOLS}
        menuModel={MENU_LCV_SECONDARY_NAV}
        menuMain={MENU_LCV_PRIMARY_NAV}
        // showMenuPanel
      />
      <main>{props.children}</main>
      <Footer
        buyingToolMenu={MENU_LCV_BUYING_TOOLS}
        carModelMenu={MENU_LCV_MODEL_LCV_FOOTER}
        serviceMenu={MENU_LCV_TEST_DRIVE_FOOTER}
        socialMenu={MENU_LCV_SOCIAL}
        footerRemark={REMARK}
        footerCopyright={COPYRIGHT}
      />
      <FloatingMenu menu={MENU_LCV_BUYING_TOOLS} />
    </>
  );
};
View.displayName = 'LCVLayout';
export default View;
