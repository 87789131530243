const Car: React.FC = () => (
  <svg width="60" height="36">
    <use
      href="/imgs/icons/common.svg#car"
      xlinkHref="/imgs/icons/common.svg#car"
    />
  </svg>
);
Car.displayName = 'SharedElementIconsCar';
export default Car;
