import { ILineUpItem } from '@/components/sections/product/line-up';
import { IImage } from '@/types/shared';

interface IData {
  price: number;
  label: string;
  url: string;
  image: IImage;
  brochure: string;
  new?: boolean;
}

interface IMenu {
  label: string;
  description: string;
  url: string;
  image: IImage;
  brochure: string;
  new?: boolean;
}

export const V_CROSS_DATA: IData = {
  price: 33400,
  label: 'ວີ-ຄຣອສ 4x4',
  url: '/isuzu-v-cross',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltf735e79288caaf22/657faccd2d26126fa1e71ef3/laos-lineup-v-cross.png',
    alt: 'v-cross 4x4',
  },
  brochure:
    'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltdcb2ce6d5826a5db/v-cross-brochure-2024.pdf',
};

export const PICKUP_4DOOR_DATA: IData = {
  price: 36900,
  label: 'ດີແມັກ 4 ປະຕູ',
  url: '/isuzu-pick-up-4-door',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt1f489d054683d877/657faccd33550e5c46d59421/laos-lineup-4-door.png',
    alt: 'pickup 4-door',
  },
  brochure:
    'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt7c99e0f554114582/4-doors-brochure-2024.pdf',
};

export const SPARK_DATA: IData = {
  price: 26000,
  label: 'ສະປ໊າກ',
  url: '/isuzu-spark',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt45aa115a8d057931/657faccd2d26124d66e71eef/laos-lineup-spark.png',
    alt: 'spark',
  },
  brochure:
    'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt1f081aa58213086f/spark-brochure-2024.pdf',
};

export const MUX_DATA: IData = {
  price: 65500,
  label: 'ມິວ-ເອັກ',
  url: '/isuzu-mu-x',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltc0ed199b5cf83a42/657faccddc76629fc07e7e75/laos-lineup-mu-x.png',
    alt: 'mu-x',
  },
  brochure:
    'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt891cc037146c8c77/mu-x-brochure-2023.pdf',
};

export const VCROSS_MENU: IMenu = {
  label: V_CROSS_DATA.label,
  description: `ເລີ່ມຕົ້ນ USD ${V_CROSS_DATA.price.toLocaleString()}`,
  url: V_CROSS_DATA.url,
  image: V_CROSS_DATA.image,
  brochure: V_CROSS_DATA.brochure,
};

export const PICKUP_4DOOR_MENU: IMenu = {
  label: PICKUP_4DOOR_DATA.label,
  description: `ເລີ່ມຕົ້ນ USD ${PICKUP_4DOOR_DATA.price.toLocaleString()}`,
  url: PICKUP_4DOOR_DATA.url,
  image: PICKUP_4DOOR_DATA.image,
  brochure: PICKUP_4DOOR_DATA.brochure,
};

export const SPARK_MENU: IMenu = {
  label: SPARK_DATA.label,
  description: `ເລີ່ມຕົ້ນ USD ${SPARK_DATA.price.toLocaleString()}`,
  url: SPARK_DATA.url,
  image: SPARK_DATA.image,
  brochure: SPARK_DATA.brochure,
};

export const MUX_MENU: IMenu = {
  label: MUX_DATA.label,
  description: `ເລີ່ມຕົ້ນ USD ${MUX_DATA.price.toLocaleString()}`,
  url: MUX_DATA.url,
  image: MUX_DATA.image,
  brochure: MUX_DATA.brochure,
  // FOR SHOW A BADGE
};

const data: ILineUpItem[] = [
  {
    category: 'v-cross 4x4',
    models: {
      image: V_CROSS_DATA.image,
      price: V_CROSS_DATA.price,
      button: {
        label: V_CROSS_DATA.label,
        url: V_CROSS_DATA.url,
        icon: 'arrow-forward',
      },
    },
  },
  {
    category: 'pickup 4-door',
    models: {
      image: PICKUP_4DOOR_DATA.image,
      price: PICKUP_4DOOR_DATA.price,
      button: {
        label: PICKUP_4DOOR_DATA.label,
        url: PICKUP_4DOOR_DATA.url,
        icon: 'arrow-forward',
      },
    },
  },
  {
    category: 'spark',
    models: {
      image: SPARK_DATA.image,
      price: SPARK_DATA.price,
      button: {
        label: SPARK_DATA.label,
        url: SPARK_DATA.url,
        icon: 'arrow-forward',
      },
    },
  },
  {
    category: 'mu-x',
    models: {
      image: MUX_DATA.image,
      price: MUX_DATA.price,
      button: {
        label: MUX_DATA.label,
        url: MUX_DATA.url,
        icon: 'arrow-forward',
      },
    },
    // FOR SHOW A BADGE
    // new: true,
  },
];
export default data;
