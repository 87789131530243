import type { BodyScrollOptions } from 'body-scroll-lock';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';
import { useSwitchTransition, useTransition } from 'transition-hook';

import Hamburger from '@/components/shared/element/hamburger';
import { IconButton } from '@/components/shared/element/icon-button';
import { getIcon, Icon } from '@/components/shared/element/icons';
import Car from '@/components/shared/element/icons/car';
import CarLCV from '@/components/shared/element/icons/car/lcv';
import { Image } from '@/components/shared/element/image';
import { Link } from '@/components/shared/element/link';
import { Logo, LogoCommon } from '@/components/shared/element/logo';
import { LogoCommonLCV, LogoLCV } from '@/components/shared/element/logo/lcv';
import NewBadge from '@/components/shared/element/new-badge';
import { Text } from '@/components/shared/element/text';
import { Box } from '@/components/shared/layout/box';
import { Container } from '@/components/shared/layout/container';
import { Flex } from '@/components/shared/layout/flex';
import { Grid } from '@/components/shared/layout/grid';
import { Button } from '@/components/shared/my24/button';
import { removeSpan } from '@/components/shared/utility/format';
import { useWindowSize } from '@/components/shared/utility/hooks';
import { BUTTON_LCV_REGISTER } from '@/contents/lcv/link';
import { IWeb } from '@/types/config';
import { IMenu } from '@/types/layout/menu';

import {
  CarContentItem,
  CarContentList,
  CarMenuItem,
  CarMenuList,
  CarMenuWrap,
  Close,
  Header,
  LogoWrap,
  MainNav,
  MenuItem,
  MenuLCV,
  MenuList,
  MenuMobileClose,
  MenuMobileTop,
  MenuMobileTopInner,
  MenuPanel,
  MenuPanelOverlay,
  OtherMenuImage,
  OtherMenuImageItem,
  OtherMenuItem,
  OtherMenuList,
  OtherMenuWrap,
  Overlay,
  PrimaryNav,
  SecondaryNav,
  SecondaryNavInner,
  SecondaryNavList,
  SecondaryNavOverlay,
  SecondaryNavOverlayInner,
  SubMenu,
  SubMenuBack,
  SubMenuBackIcon,
  SubMenuCar,
  SubMenuCarGroup,
  SubMenuCarLCV,
  SubMenuInner,
} from './styled';

const options: BodyScrollOptions = {
  reserveScrollBarGap: true,
};

interface ICarMenuView extends IMenu {
  currentSubmenu?: number;
}
interface IProps {
  menuBuyingTool?: IMenu[];
  menuMain?: IMenu[];
  menuModel?: IMenu[];
  menuRegister?: IMenu;
  showMenuPanel?: boolean;
}

const CarMenuLayoutCV = (props: ICarMenuView) => {
  const [toggle, setToggle] = useState(true);

  useEffect(() => {
    if (props.currentSubmenu == -1) {
      setToggle(true);
    }
  }, [props.currentSubmenu]);

  return (
    <Box>
      <Flex
        justify="between"
        align="center"
        css={{
          px: '$6',
          height: 'var(--primary-nav-height)',
          cursor: 'pointer',
          '@sm': {
            px: '$11',
          },
          '@lg': {
            display: 'none',
          },
        }}
        onClick={() => setToggle(!toggle)}
      >
        <Text
          size="h5"
          variant="text-gray"
          font="bold"
          css={{ pt: 2, fontSize: 18 }}
        >
          {props.label}
        </Text>
        <Icon variant="icon-black">
          {toggle ? getIcon('expand-less') : getIcon('expand-more')}
        </Icon>
      </Flex>

      {props.submenu && props.submenu.length > 0 && toggle && (
        <SubMenuCarGroup
          className={`SubMenuCarGroup
          ${
            props.submenu.filter((item) => item.label).length > 0
              ? 'is-1-column'
              : 'is-2-column'
          }
        `}
        >
          {props.submenu.map((sub, i) => (
            <Box key={i}>
              {sub.label && (
                <Box
                  className="car-title"
                  css={{
                    mb: '$2',
                    '@lg': {
                      mb: '$4',
                    },
                  }}
                >
                  <Text
                    size="xs"
                    font="bold"
                    variant="text-gray"
                    css={{ '@lg': { display: 'none' } }}
                  >
                    {sub.label}
                  </Text>
                  <Text
                    size="h6"
                    font="bold"
                    variant="text-gray"
                    css={{ '@maxlg': { display: 'none' } }}
                  >
                    {sub.label}
                  </Text>
                </Box>
              )}
              {sub.submenu && sub.submenu.length > 0 && (
                <SubMenuCar
                  size="1"
                  className={`SubMenuCar
                  ${sub.submenu.length == 1 ? 'is-1-column' : 'is-2-column'}
                  `}
                >
                  {sub.submenu.map((j, i) => (
                    <Link
                      className="sub-menu-car-link"
                      href={j.url}
                      key={i}
                      tracking={{
                        dataTrack: 'primary-nav',
                        dataTrackText: j.label,
                        dataTrackUrl: j.url,
                      }}
                    >
                      <Box
                        css={{
                          maxWidth: 192,
                          '@lg': {
                            width: 192,
                          },
                        }}
                      >
                        <Text
                          size={{
                            '@initial': 'base',
                            '@lg': 'h6',
                          }}
                          font="bold"
                          variant="text-black"
                        >
                          {j.label}
                        </Text>
                        {j.image && (
                          <Box
                            className="car-image"
                            css={{
                              '@lg': {
                                mt: '$4',
                              },
                            }}
                          >
                            <Image
                              fade={false}
                              blur={false}
                              ratio
                              size="4by3"
                              src={j.image.src}
                              alt={j.image.alt}
                              srcset={[
                                {
                                  src: j.image.src,
                                  breakpoint: 0,
                                  width: 280,
                                },
                              ]}
                            />
                          </Box>
                        )}
                      </Box>
                    </Link>
                  ))}
                </SubMenuCar>
              )}
            </Box>
          ))}
        </SubMenuCarGroup>
      )}
    </Box>
  );
};

const CarMenuLayoutLCV = (props: ICarMenuView) => {
  return (
    <Grid gapY={{ '@lg': 4 }}>
      {props.label && (
        <Text size="h6" font="bold" variant="text-gray">
          {props.label}
        </Text>
      )}
      {props.submenu && props.submenu.length > 0 && (
        <SubMenuCarLCV>
          {props.submenu.map((sub, i) => (
            <React.Fragment key={i}>
              {sub.submenu && sub.submenu.length > 0 && (
                <SubMenuCar size="2">
                  {sub.submenu.map((j, i) => (
                    <Link
                      className="sub-menu-car-link"
                      href={j.url}
                      key={i}
                      tracking={{
                        dataTrack: 'primary-nav',
                        dataTrackText: j.label,
                        dataTrackUrl: j.url,
                      }}
                    >
                      <Box>
                        <Text
                          size={{
                            '@initial': 'base',
                            '@lg': 'h6',
                          }}
                          font="bold"
                          variant="text-black"
                        >
                          {j.label}
                        </Text>
                        <Text size="2xs" variant="text-gray-dark">
                          {j.description}
                        </Text>
                      </Box>
                      {j.image && (
                        <Box className="car-image">
                          {j.new && (
                            <NewBadge
                              css={{
                                top: -14,
                                '@lg': {
                                  top: -8,
                                },
                              }}
                            />
                          )}
                          <Image
                            fade={false}
                            blur={false}
                            ratio
                            size="4by3"
                            src={j.image.src}
                            alt={j.image.alt}
                            srcset={[
                              {
                                src: j.image.src,
                                breakpoint: 0,
                                width: 280,
                              },
                            ]}
                          />
                        </Box>
                      )}
                    </Link>
                  ))}
                </SubMenuCar>
              )}
            </React.Fragment>
          ))}
        </SubMenuCarLCV>
      )}
    </Grid>
  );
};

const View: React.FunctionComponent<IProps> = (props: IProps) => {
  const WEB = process.env.NEXT_PUBLIC_WEB as IWeb;
  const windowSize = useWindowSize();
  const [submenu, setSubmenu] = useState(-1);
  const [selectedTab, setSelectedTab] = useState(0);

  const [toggleMenu, setToggleMenu] = useState(false);
  const [toggleBuyingTools, setToggleBuyingTools] = useState(false);
  const [mouseOver, setMouseOver] = useState(0);

  const [togglePanel, setTogglePanel] = useState(false);

  const menuToggleBuyingTools = useRef(null);
  const menuTogglePanel = useRef(null);
  const menuToggle = useRef(null);
  const subMenuToggle = useRef(null);
  const refArray = useRef([]);

  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = () => {
      setSubmenu(-1);
      setSelectedTab(0);
      setToggleMenu(false);
      setToggleBuyingTools(false);
      setMouseOver(0);
      setTogglePanel(false);

      clearAllBodyScrollLocks();
      document.querySelector('header').classList.remove('is-product-menu-open');
      document.body.style.overflow = '';
      document.body.style.paddingRight = '';
    };

    router.events.on('routeChangeStart', handleRouteChange);
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    if (windowSize.width >= 1024) {
      if (toggleBuyingTools) {
        setToggleBuyingTools(false);
      }
      if (toggleMenu) {
        setToggleMenu(false);
        setSubmenu(-1);
      }
    } else {
      if (!toggleMenu && submenu !== -1) {
        setSubmenu(-1);
      }
      if (togglePanel) {
        setTogglePanel(false);
      }
    }
  }, [toggleMenu, submenu, toggleBuyingTools, togglePanel, windowSize.width]);

  useEffect(() => {
    if (toggleMenu) {
      setToggleBuyingTools(false);
      document.documentElement.style.setProperty(
        '--menu-height',
        `${window.innerHeight}px`
      );
    } else {
      setSubmenu(-1);
    }
  }, [toggleMenu]);

  useEffect(() => {
    if (submenu !== -1) {
      setTogglePanel(false);
    }
  }, [submenu]);

  useEffect(() => {
    if (togglePanel) {
      setSubmenu(-1);
    }
  }, [togglePanel]);

  // NOTE: check lock scroll
  useEffect(() => {
    if (
      toggleBuyingTools || // mobile: buying tools
      toggleMenu || // mobile: menu
      (!toggleMenu && submenu !== -1) || // desktop: menu
      togglePanel // desktop: panel
    ) {
      // mobile: buying tools
      if (toggleBuyingTools) {
        menuToggleBuyingTools.current &&
          disableBodyScroll(menuToggleBuyingTools.current, options);
        document.body.style.overflow = 'hidden';
        document.body.style.paddingRight = 'var(--scrollbar)';
        return;
      }

      // mobile: menu
      if (toggleMenu) {
        clearAllBodyScrollLocks();
        document.body.style.overflow = '';
        document.body.style.paddingRight = '';

        if (submenu === -1) {
          menuToggle.current && disableBodyScroll(menuToggle.current, options);
          document.body.style.overflow = 'hidden';
          document.body.style.paddingRight = 'var(--scrollbar)';
        } else {
          refArray.current[submenu] &&
            disableBodyScroll(refArray.current[submenu], options);
          document.body.style.overflow = 'hidden';
          document.body.style.paddingRight = 'var(--scrollbar)';
        }
        return;
      }

      // desktop: submenu
      if (!toggleMenu && submenu !== -1) {
        subMenuToggle.current &&
          disableBodyScroll(subMenuToggle.current, options);
        document.body.style.overflow = 'hidden';
        document.body.style.paddingRight = 'var(--scrollbar)';
        return;
      }

      // desktop: panel
      if (togglePanel) {
        menuTogglePanel.current &&
          disableBodyScroll(menuTogglePanel.current, options);
        document.body.style.overflow = 'hidden';
        document.body.style.paddingRight = 'var(--scrollbar)';
        return;
      }
    } else {
      clearAllBodyScrollLocks();
      document.body.style.overflow = '';
      document.body.style.paddingRight = '';
    }
  }, [toggleBuyingTools, togglePanel, submenu, toggleMenu]);

  const PANEL_TRANS = useTransition(togglePanel, 600);
  const PANEL_OVERLAY_TRANS = useTransition(togglePanel, 1000);

  const SECONDARY_NAV_BUYING_TOOLS_OVERLAY_TRANS = useTransition(
    toggleBuyingTools,
    1000
  );

  const transition = useSwitchTransition(submenu, 600, 'default');
  const OVERLAY_TRANS = useTransition(submenu !== -1, 1000);

  return (
    <Header>
      {SECONDARY_NAV_BUYING_TOOLS_OVERLAY_TRANS.shouldMount && (
        <SecondaryNavOverlay
          onClick={() => setToggleBuyingTools(!toggleBuyingTools)}
          className={toggleBuyingTools && 'is-buying-tools-open'}
          css={{
            pe:
              SECONDARY_NAV_BUYING_TOOLS_OVERLAY_TRANS.stage === 'enter'
                ? 'all'
                : 'none',
            opacity:
              SECONDARY_NAV_BUYING_TOOLS_OVERLAY_TRANS.stage === 'enter'
                ? '1'
                : '0',
          }}
        />
      )}

      <SecondaryNav className={toggleBuyingTools && 'is-buying-tools-open'}>
        <Container size="5">
          <SecondaryNavInner ref={menuToggleBuyingTools}>
            <SecondaryNavList>
              {props.menuModel.map((o, i) => (
                <Link
                  key={i}
                  className={`secondary-nav-link ${o.active && 'is-current'}`}
                  href={o.url}
                  target={o.target}
                  tracking={{
                    dataTrack: 'secondary-nav',
                    dataTrackUrl: o.url,
                    dataTrackText: o.label,
                  }}
                >
                  <Text
                    size="xs"
                    variant="text-white"
                    font={o.active ? 'bold' : 'base'}
                    css={!o.active && { '@lg': { pt: 1 } }}
                  >
                    {o.label}
                  </Text>
                </Link>
              ))}
            </SecondaryNavList>
            <SecondaryNavList size="2">
              {props.menuBuyingTool.map((o, i) => (
                <Link
                  key={i}
                  className="secondary-nav-link"
                  href={o.url}
                  target={o.target}
                  tracking={{
                    dataTrack: 'secondary-nav',
                    dataTrackUrl: o.url,
                    dataTrackText: removeSpan(o.label),
                  }}
                >
                  {o.icon && (
                    <Icon
                      size={{ '@initial': '3', '@lg': '1' }}
                      variant={{
                        '@initial': 'icon-red',
                        '@lg': 'icon-white',
                      }}
                    >
                      {getIcon(o.icon)}
                    </Icon>
                  )}
                  {o.label && (
                    <Box>
                      <Text
                        size={{ '@initial': 'base', '@lg': 'xs' }}
                        font={{ '@initial': 'bold', '@lg': 'base' }}
                        variant={{
                          '@initial': 'text-gray-darkest',
                          '@lg': 'text-white',
                        }}
                        css={{ '@lg': { pt: 1 } }}
                        wrap
                        dangerouslySetInnerHTML={{ __html: o.label }}
                      />
                      <Text
                        size="sm"
                        variant="text-gray-dark"
                        css={{ '@lg': { display: 'none' } }}
                        wrap
                        dangerouslySetInnerHTML={{ __html: o.description }}
                      />
                    </Box>
                  )}
                </Link>
              ))}
            </SecondaryNavList>
            {toggleBuyingTools && (
              <>
                <Box
                  css={{
                    cursor: 'pointer',
                    py: '$4',
                    textAlign: 'center',
                    '@lg': {
                      display: 'none',
                    },
                  }}
                  onClick={() => setToggleBuyingTools(!toggleBuyingTools)}
                >
                  <IconButton size="4" variant="icon-button-white" shadow>
                    <Icon>{getIcon('close')}</Icon>
                  </IconButton>
                </Box>
                <SecondaryNavOverlayInner
                  onClick={() => setToggleBuyingTools(!toggleBuyingTools)}
                />
              </>
            )}
          </SecondaryNavInner>
        </Container>
      </SecondaryNav>

      <PrimaryNav className={submenu !== -1 && 'is-subnav-open'}>
        <Container>
          <Flex
            justify="between"
            align="center"
            css={{
              fontSize: '0',
              height: 'var(--primary-nav-height)',
            }}
          >
            <LogoWrap>
              <Link
                href="/"
                tracking={{
                  dataTrack: 'primary-nav',
                  dataTrackText: 'logo',
                  dataTrackUrl: '/',
                }}
              >
                {WEB === 'lcv' ? <LogoLCV /> : <Logo />}
              </Link>
            </LogoWrap>
            <Flex
              justify="between"
              align="center"
              gap={{ '@initial': '6', '@md': '7' }}
              css={{ height: '100%' }}
            >
              <MainNav className={toggleMenu && 'is-nav-open'}>
                {OVERLAY_TRANS.shouldMount && (
                  <Overlay
                    className={submenu !== -1 && 'is-subnav-open'}
                    onClick={() => setSubmenu(-1)}
                    css={{
                      '@lg': {
                        pe: OVERLAY_TRANS.stage === 'enter' ? 'all' : 'none',
                        opacity: OVERLAY_TRANS.stage === 'enter' ? '1' : '0',
                      },
                    }}
                  />
                )}
                <MenuList
                  ref={menuToggle}
                  variant="cv"
                  className={submenu !== -1 && 'is-subnav-open'}
                >
                  {props.menuMain.map((o, i) => (
                    <MenuItem
                      key={i}
                      variant="cv"
                      css={o.showPanel && { '@lg': { display: 'none' } }}
                    >
                      {o.url ? (
                        <Link
                          href={o.url}
                          target={o.target}
                          tracking={{
                            dataTrack: 'primary-nav',
                            dataTrackText: o.label,
                            dataTrackUrl: o.url,
                          }}
                        >
                          <Text
                            size={{
                              '@initial': 'h5',
                              '@lg': 'h6',
                            }}
                            variant="text-black"
                            css={{
                              pt: 2,
                              fontSize: 18,
                              '@lg': {
                                pt: 2,
                              },
                            }}
                            dangerouslySetInnerHTML={{ __html: o.label }}
                          ></Text>
                        </Link>
                      ) : (
                        <Box
                          key={i}
                          className={`menu-link ${
                            submenu === i && 'is-menu-open'
                          }`}
                          tracking={{
                            dataTrack: 'primary-nav',
                            dataTrackText: o.label,
                            dataTrackUrl: o.url,
                          }}
                          onClick={() => {
                            submenu === i ? setSubmenu(-1) : setSubmenu(i);
                          }}
                        >
                          <Text
                            size={{
                              '@initial': 'h5',
                              '@lg': 'h6',
                            }}
                            variant="text-black"
                            css={{
                              pt: 2,
                              fontSize: 18,
                              '@lg': {
                                pt: 2,
                              },
                            }}
                          >
                            {o.label}
                          </Text>
                          {o.submenu && o.submenu.length > 0 && (
                            <>
                              {!toggleMenu && (
                                <Icon
                                  variant="icon-black"
                                  css={{
                                    '@maxlg': {
                                      display: 'none',
                                    },
                                  }}
                                >
                                  {getIcon('expand-more')}
                                </Icon>
                              )}
                              {toggleMenu && (
                                <Icon
                                  variant="icon-black"
                                  css={{
                                    '@lg': {
                                      display: 'none',
                                    },
                                  }}
                                >
                                  {getIcon('chevron-right')}
                                </Icon>
                              )}
                            </>
                          )}
                        </Box>
                      )}
                      {o.submenu && o.submenu.length > 0 && (
                        <>
                          {transition((state, stage) => (
                            <>
                              {state === i && (
                                <SubMenu
                                  ref={subMenuToggle}
                                  className={submenu === i && 'is-subnav-open'}
                                  css={{
                                    '@maxlg': {
                                      pe: stage === 'enter' ? 'all' : 'none',
                                      transform:
                                        stage === 'enter'
                                          ? 'translateX(0)'
                                          : 'translateX(100%)',
                                      transitionProperty: 'transform',
                                      transitionDuration: '0.6s',
                                      transitionTimingFunction:
                                        'var(--transition-easing)',
                                      willChange: 'transform',
                                    },
                                    '@lg': {
                                      pe: stage === 'enter' ? 'all' : 'none',
                                      opacity: stage === 'enter' ? '1' : '0',
                                      transform:
                                        stage === 'enter'
                                          ? 'translateY(0)'
                                          : 'translateY(-40px)',
                                      transitionProperty: 'opacity, transform',
                                      transitionDuration: '0.6s',
                                      transitionTimingFunction:
                                        'var(--transition-easing)',
                                      willChange: 'opacity, transform',
                                    },
                                  }}
                                >
                                  {o.layout === 1 && (
                                    <Container size="5">
                                      <SubMenuBack
                                        onClick={() => setSubmenu(-1)}
                                      >
                                        <Flex gapX="1" align="center">
                                          <SubMenuBackIcon>
                                            <Icon variant="icon-red">
                                              {getIcon('chevron-left')}
                                            </Icon>
                                          </SubMenuBackIcon>
                                          <Text
                                            size="xl"
                                            variant="text-red"
                                            css={{
                                              pt: 2,
                                              lineHeight:
                                                'calc(var(--primary-nav-height) - 1px - 2px)',
                                            }}
                                          >
                                            {o.label}
                                          </Text>
                                        </Flex>
                                        <MenuMobileClose
                                          onClick={() =>
                                            setToggleMenu(!toggleMenu)
                                          }
                                        >
                                          <Icon variant="icon-black">
                                            {getIcon('close')}
                                          </Icon>
                                        </MenuMobileClose>
                                      </SubMenuBack>
                                      <CarMenuWrap
                                        ref={(ref) => {
                                          refArray.current[i] = ref;
                                        }}
                                      >
                                        <CarMenuList>
                                          {o.submenu.map((k, i) => {
                                            return (
                                              <CarMenuItem
                                                key={i}
                                                className={
                                                  i == selectedTab
                                                    ? 'is-current'
                                                    : null
                                                }
                                                onClick={() =>
                                                  setSelectedTab(i)
                                                }
                                              >
                                                <Text
                                                  size="h6"
                                                  variant="text-black"
                                                >
                                                  {k.label}
                                                </Text>
                                              </CarMenuItem>
                                            );
                                          })}
                                        </CarMenuList>
                                        <CarContentList size="1">
                                          {o.submenu.map((k, i) => {
                                            return (
                                              <CarContentItem
                                                key={i}
                                                size="1"
                                                className={
                                                  i == selectedTab
                                                    ? 'is-current'
                                                    : null
                                                }
                                              >
                                                <CarMenuLayoutCV
                                                  currentSubmenu={submenu}
                                                  {...k}
                                                />
                                              </CarContentItem>
                                            );
                                          })}
                                        </CarContentList>
                                        <Close onClick={() => setSubmenu(-1)}>
                                          <IconButton
                                            size="4"
                                            variant="icon-button-gray"
                                            nobg
                                          >
                                            <Icon>{getIcon('close')}</Icon>
                                          </IconButton>
                                        </Close>
                                      </CarMenuWrap>
                                    </Container>
                                  )}
                                  {o.layout === 2 && (
                                    <Container size="5">
                                      <SubMenuBack
                                        onClick={() => setSubmenu(-1)}
                                      >
                                        <Flex gapX="1" align="center">
                                          <SubMenuBackIcon>
                                            <Icon variant="icon-red">
                                              {getIcon('chevron-left')}
                                            </Icon>
                                          </SubMenuBackIcon>
                                          <Text
                                            size="xl"
                                            variant="text-red"
                                            css={{
                                              pt: 2,
                                              lineHeight:
                                                'calc(var(--primary-nav-height) - 1px - 2px)',
                                            }}
                                          >
                                            {o.label}
                                          </Text>
                                        </Flex>
                                        <MenuMobileClose
                                          onClick={() =>
                                            setToggleMenu(!toggleMenu)
                                          }
                                        >
                                          <Icon variant="icon-black">
                                            {getIcon('close')}
                                          </Icon>
                                        </MenuMobileClose>
                                      </SubMenuBack>
                                      <CarMenuWrap
                                        ref={(ref) => {
                                          refArray.current[i] = ref;
                                        }}
                                      >
                                        <CarContentList size="2">
                                          {o.submenu.map((k, i) => (
                                            <CarContentItem size="2" key={i}>
                                              <CarMenuLayoutLCV {...k} />
                                            </CarContentItem>
                                          ))}
                                        </CarContentList>
                                        <Close onClick={() => setSubmenu(-1)}>
                                          <IconButton
                                            size="4"
                                            variant="icon-button-gray"
                                            nobg
                                          >
                                            <Icon>{getIcon('close')}</Icon>
                                          </IconButton>
                                        </Close>
                                      </CarMenuWrap>
                                    </Container>
                                  )}
                                  {o.layout !== 1 && o.layout !== 2 && (
                                    <SubMenuInner>
                                      <Container size="5">
                                        <SubMenuBack
                                          onClick={() => setSubmenu(-1)}
                                        >
                                          <Flex gapX="1" align="center">
                                            <SubMenuBackIcon>
                                              <Icon variant="icon-red">
                                                {getIcon('chevron-left')}
                                              </Icon>
                                            </SubMenuBackIcon>
                                            <Text
                                              size="xl"
                                              variant="text-red"
                                              css={{
                                                pt: 2,
                                                lineHeight:
                                                  'calc(var(--primary-nav-height) - 1px - 2px)',
                                              }}
                                            >
                                              {o.label}
                                            </Text>
                                          </Flex>
                                          <MenuMobileClose
                                            onClick={() =>
                                              setToggleMenu(!toggleMenu)
                                            }
                                          >
                                            <Icon variant="icon-black">
                                              {getIcon('close')}
                                            </Icon>
                                          </MenuMobileClose>
                                        </SubMenuBack>
                                        <OtherMenuWrap
                                          ref={(ref) => {
                                            refArray.current[i] = ref;
                                          }}
                                        >
                                          <Grid
                                            columns={{ '@lg': '12' }}
                                            gapX={{ '@lg': '2' }}
                                            css={{
                                              '@lg': {
                                                minHeight: 360,
                                              },
                                            }}
                                          >
                                            <Box
                                              css={{
                                                '@lg': {
                                                  position: 'relative',
                                                  gridColumn: '1 / span 5',
                                                },
                                                '@maxlg': {
                                                  display: 'none',
                                                },
                                              }}
                                            >
                                              <OtherMenuImage>
                                                {o.submenu.map((k, i) => {
                                                  return (
                                                    <OtherMenuImageItem
                                                      key={i}
                                                      className={
                                                        i === mouseOver &&
                                                        'is-current'
                                                      }
                                                    >
                                                      <Image
                                                        fullheight
                                                        blur
                                                        fade={false}
                                                        src={k.image.src}
                                                        alt={k.image.alt}
                                                        srcset={[
                                                          {
                                                            src: k.image.src,
                                                            breakpoint: 0,
                                                            width: 600,
                                                          },
                                                          {
                                                            src: k.image.src,
                                                            breakpoint: 1680,
                                                            width: 800,
                                                          },
                                                        ]}
                                                      />
                                                    </OtherMenuImageItem>
                                                  );
                                                })}
                                              </OtherMenuImage>
                                            </Box>
                                            <Box
                                              css={{
                                                '@lg': {
                                                  gridColumn: '8 / span 5',
                                                },
                                              }}
                                            >
                                              <OtherMenuList>
                                                {o.submenu.map((k, i) => (
                                                  <OtherMenuItem
                                                    key={i}
                                                    className={
                                                      i == mouseOver
                                                        ? 'is-current'
                                                        : null
                                                    }
                                                    onMouseOver={() =>
                                                      setMouseOver(i)
                                                    }
                                                  >
                                                    <Link
                                                      className="other-menu-link"
                                                      href={k.url}
                                                      target={k.target}
                                                      tracking={{
                                                        dataTrack:
                                                          'primary-nav',
                                                        dataTrackText: k.label,
                                                        dataTrackUrl: k.url,
                                                      }}
                                                    >
                                                      <Text
                                                        size={{
                                                          '@initial': 'h5',
                                                          '@lg': 'h6',
                                                        }}
                                                        variant="text-black"
                                                        css={{
                                                          pt: 2,
                                                          fontSize: 18,
                                                        }}
                                                      >
                                                        {k.label}
                                                      </Text>
                                                      {k.target && (
                                                        <Icon
                                                          variant="icon-black"
                                                          css={{
                                                            '@lg': {
                                                              display: 'none',
                                                            },
                                                          }}
                                                        >
                                                          {getIcon(
                                                            'open-in-new'
                                                          )}
                                                        </Icon>
                                                      )}
                                                    </Link>
                                                  </OtherMenuItem>
                                                ))}
                                              </OtherMenuList>
                                            </Box>
                                          </Grid>
                                          <Close onClick={() => setSubmenu(-1)}>
                                            <IconButton
                                              size="4"
                                              variant="icon-button-gray"
                                              nobg
                                            >
                                              <Icon>{getIcon('close')}</Icon>
                                            </IconButton>
                                          </Close>
                                        </OtherMenuWrap>
                                      </Container>
                                    </SubMenuInner>
                                  )}
                                </SubMenu>
                              )}
                            </>
                          ))}
                        </>
                      )}
                    </MenuItem>
                  ))}
                  {toggleMenu &&
                    props.menuModel
                      .filter((o) => o.active !== true)
                      .map((o, i) => (
                        <MenuLCV key={i}>
                          <Link
                            href={o.url}
                            target={o.target}
                            tracking={{
                              dataTrack: 'primary-nav',
                              dataTrackText: o.label,
                              dataTrackUrl: o.url,
                            }}
                          >
                            <Flex align="center" gapX="3">
                              {WEB === 'lcv' ? <CarLCV /> : <Car />}
                              <Text
                                size={{
                                  '@initial': 'h5',
                                  '@lg': 'h6',
                                }}
                                variant="text-black"
                                css={{
                                  pt: 2,
                                  fontSize: 18,
                                  '@lg': {
                                    pt: 2,
                                  },
                                }}
                              >
                                {o.label}
                              </Text>
                            </Flex>
                            {o.target && (
                              <Icon variant="icon-black">
                                {getIcon('open-in-new')}
                              </Icon>
                            )}
                          </Link>
                        </MenuLCV>
                      ))}
                </MenuList>
                {toggleMenu && (
                  <MenuMobileTop
                    variant="cv"
                    className={submenu !== -1 && 'is-subnav-open'}
                  >
                    <MenuMobileTopInner>
                      <LogoWrap>
                        <Link
                          href="/"
                          tracking={{
                            dataTrack: 'primary-nav',
                            dataTrackText: 'logo',
                            dataTrackUrl: '/',
                          }}
                        >
                          {WEB === 'lcv' ? <LogoCommonLCV /> : <LogoCommon />}
                        </Link>
                      </LogoWrap>
                      <MenuMobileClose
                        onClick={() => setToggleMenu(!toggleMenu)}
                      >
                        <Icon variant="icon-black">{getIcon('close')}</Icon>
                      </MenuMobileClose>
                    </MenuMobileTopInner>
                  </MenuMobileTop>
                )}
              </MainNav>

              <Box
              // onClick={() => setToggleBuyingTools(!toggleBuyingTools)}
              // css={{
              //   '@lg': {
              //     display: 'none',
              //   },
              // }}
              >
                <Button
                  label={BUTTON_LCV_REGISTER.label}
                  href={BUTTON_LCV_REGISTER.url}
                  variant={'primary'}
                  tracking={{
                    dataTrack: 'secondary-nav',
                    dataTrackText: 'สนใจรถ',
                    dataTrackUrl: '',
                  }}
                />
              </Box>

              <Box
                onClick={() => setToggleMenu(!toggleMenu)}
                css={{
                  '@lg': {
                    display: 'none',
                  },
                }}
                test={{
                  dataTest: 'hamburger',
                }}
                cy="hamburger"
              >
                <Hamburger />
              </Box>

              {props.showMenuPanel && (
                <>
                  <Box
                    onClick={() => setTogglePanel(!togglePanel)}
                    css={{
                      display: 'none',
                      '@lg': {
                        display: 'block',
                      },
                    }}
                    test={{
                      dataTest: 'hamburger',
                    }}
                    cy="hamburger"
                  >
                    <Hamburger />
                  </Box>

                  {PANEL_OVERLAY_TRANS.shouldMount && (
                    <MenuPanelOverlay
                      onClick={() => setTogglePanel(!togglePanel)}
                      className={togglePanel && 'is-nav-open'}
                      css={{
                        pe:
                          PANEL_OVERLAY_TRANS.stage === 'enter'
                            ? 'all'
                            : 'none',
                        opacity:
                          PANEL_OVERLAY_TRANS.stage === 'enter' ? '1' : '0',
                      }}
                    />
                  )}
                  {PANEL_TRANS.shouldMount && (
                    <MenuPanel
                      css={{
                        pe: PANEL_TRANS.stage === 'enter' ? 'all' : 'none',
                        transform:
                          PANEL_TRANS.stage === 'enter'
                            ? 'translateX(0)'
                            : 'translateX(100%)',
                      }}
                      className={togglePanel && 'is-nav-open'}
                    >
                      <MenuMobileTop variant="lcv">
                        <MenuMobileTopInner>
                          <LogoWrap>
                            <Link
                              href="/"
                              tracking={{
                                dataTrack: 'primary-nav',
                                dataTrackText: 'logo panel',
                                dataTrackUrl: '/',
                              }}
                            >
                              {WEB === 'lcv' ? (
                                <LogoCommonLCV />
                              ) : (
                                <LogoCommon />
                              )}
                            </Link>
                          </LogoWrap>
                          <MenuMobileClose
                            onClick={() => setTogglePanel(!togglePanel)}
                          >
                            <Icon variant="icon-black">{getIcon('close')}</Icon>
                          </MenuMobileClose>
                        </MenuMobileTopInner>
                      </MenuMobileTop>
                      <MenuList variant="lcv" ref={menuTogglePanel}>
                        {props.menuMain.map((o, i) => (
                          <React.Fragment key={i}>
                            {o.showPanel && (
                              <MenuItem variant="lcv">
                                {o.url && (
                                  <Link
                                    href={o.url}
                                    target={o.target}
                                    tracking={{
                                      dataTrack: 'primary-nav',
                                      dataTrackText: o.label,
                                      dataTrackUrl: o.url,
                                    }}
                                  >
                                    <Text
                                      size={{
                                        '@initial': 'h5',
                                        '@lg': 'h6',
                                      }}
                                      variant="text-black"
                                      css={{
                                        pt: 2,
                                        fontSize: 18,
                                        '@lg': {
                                          pt: 2,
                                        },
                                      }}
                                    >
                                      {o.label}
                                    </Text>
                                  </Link>
                                )}
                              </MenuItem>
                            )}
                          </React.Fragment>
                        ))}
                      </MenuList>
                    </MenuPanel>
                  )}
                </>
              )}
            </Flex>
          </Flex>
        </Container>
      </PrimaryNav>
    </Header>
  );
};
View.displayName = 'Header';
export default View;
