import { IMenu } from '@/types/layout/menu';
import { IButton } from '@/types/shared/button';

import {
  MUX_MENU,
  PICKUP_4DOOR_MENU,
  SPARK_MENU,
  VCROSS_MENU,
} from './product/cafe/lineup';

export const MENU_LCV_SECONDARY_NAV_LCV: IMenu = {
  label: 'รถปิกอัพและรถอเนกประสงค์',
  url: '/',
  active: true,
};

export const MENU_LCV_SECONDARY_NAV: IMenu[] = [MENU_LCV_SECONDARY_NAV_LCV];

// LCV BUYING TOOLS
export const MENU_LCV_REGISTER: IMenu = {
  label: 'ສົນໃຈລົດ / ທົດລອງຂັບ',
  description: `ປະສົບການທີ່ແທ້ຈິງ`,
  url: '/register',
  icon: 'steering-wheel',
};

export const MENU_LCV_CALCULATOR: IMenu = {
  label: 'ຄໍານວນຄ່າງວດ',
  description: `ລອງຄິດໄລ່ຄ່າງວດທີ່ຕ້ອງຈ່າຍ`,
  url: '/calculator',
  icon: 'calculator',
};

export const MENU_LCV_ABOUT_CONTACT: IMenu = {
  label: 'ຕິດຕໍ່ເຮົາ',
  description: `ຊອກຫາຕົວແທນອີຊູຊຸໃກ້ທ່ານ`,
  url: '/contact-us',
  icon: 'place',
};

export const MENU_LCV_DOWNLOAD_BROCHURE: IMenu = {
  label: 'ດາວໂຫຼດ<span>ໂບຊົວ</span>',
  description: `ລາຍລະອຽດລົດອີຊູຊຸທຸກລຸ້ນ`,
  url: '/download-brochure',
  icon: 'map',
};

export const MENU_LCV_DEALER: IMenu = {
  label: 'ค้นหาโชว์รูม / <span>ศูนย์บริการ</span>',
  description: `ค้นหาโชว์รูมอีซูซุ<span>ใกล้บ้านคุณ</span><span>ได้ที่นี่</span>`,
  url: '/dealer',
  icon: 'place',
};

export const MENU_LCV_BUYING_TOOLS: IMenu[] = [
  MENU_LCV_REGISTER,
  MENU_LCV_CALCULATOR,
  MENU_LCV_DOWNLOAD_BROCHURE,
  MENU_LCV_ABOUT_CONTACT,
];

// LCV MODEL
export const MENU_V_CROSS: IMenu = VCROSS_MENU;
export const MENU_4_DOOR: IMenu = PICKUP_4DOOR_MENU;
export const MENU_SPARK: IMenu = SPARK_MENU;
export const MENU_MU_X: IMenu = MUX_MENU;

export const MENU_LCV_MODEL_LABEL = `ລຸ້ນລົດອີຊູຊຸ`;
export const MENU_LCV_MODEL_PICKUP_LABEL = `ລົດກະບະ`;
export const MENU_LCV_MODEL_SUV_LABEL = `ລົດອະເນກປະສົງ`;
export const MENU_LCV_MODEL_D_MAX_LABEL = `ອີຊູຊຸດີແມັກ`;
export const MENU_LCV_MODEL_MU_X_LABEL = `ມິວ-ເອັກ`;
export const MENU_LCV_MODEL_HEADER: IMenu = {
  label: MENU_LCV_MODEL_LABEL,
  url: '',
  layout: 2,
  submenu: [
    {
      url: '',
      label: MENU_LCV_MODEL_PICKUP_LABEL,
      submenu: [
        {
          url: '',
          label: MENU_LCV_MODEL_D_MAX_LABEL,
          submenu: [MENU_V_CROSS],
        },
        {
          url: '',
          label: MENU_LCV_MODEL_D_MAX_LABEL,
          submenu: [MENU_4_DOOR],
        },
        {
          url: '',
          label: MENU_LCV_MODEL_D_MAX_LABEL,
          submenu: [MENU_SPARK],
        },
      ],
    },
    {
      url: '',
      label: MENU_LCV_MODEL_SUV_LABEL,
      submenu: [
        {
          url: '',
          label: MENU_LCV_MODEL_MU_X_LABEL,
          submenu: [MENU_MU_X],
        },
      ],
    },
  ],
};

export const MENU_LCV_MODEL_LCV_FOOTER: IMenu = {
  label: MENU_LCV_MODEL_LABEL,
  url: '',
  submenuArray: [
    {
      label: MENU_LCV_MODEL_PICKUP_LABEL,
      submenu: [MENU_V_CROSS, MENU_4_DOOR, MENU_SPARK],
    },
    {
      label: MENU_LCV_MODEL_SUV_LABEL,
      submenu: [MENU_MU_X],
    },
  ],
};
// LCV SPECIAL OFFER
export const MENU_LCV_SPECIAL_OFFER: IMenu = {
  label: 'ข้อเสนอพิเศษ',
  url: '/specialoffer',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt210f1c5ac42f10e4/651e8491102b7b8e9d0b2f05/my24-special-pro.jpg',
    alt: 'offers',
  },
};

// LCV SERVICE
export const MENU_LCV_SERVICE_CENTER: IMenu = {
  label: 'ມາດຕະຖານສູນບໍລິການ',
  url: '/service-center-standard',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt8fb4c39fd86ca570/64f53e9556282b5b434dc38c/img-1-service-center.jpg',
    alt: 'service center',
  },
};

export const MENU_LCV_SERVICE_MAINTENANCE_LIST: IMenu = {
  label: 'รายการบำรุงรักษาตามระยะ',
  url: '/service/maintenance-list',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltd4ccb6c593303459/627886a2220ab5285f51c192/maintenance.jpg',
    alt: 'maintenance list',
  },
};

export const MENU_LCV_SERVICE_PARTS: IMenu = {
  label: 'อะไหล่สำหรับรถอีซูซุ',
  url: '/service/parts',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt7110f5853f6fa8e9/627886a2deee7329767ea589/parts.jpg',
    alt: 'isuzu parts',
  },
};

export const MENU_LCV_SERVICE_WARRANTY: IMenu = {
  label: 'การรับประกัน',
  url: '/service/warranty',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltf415e48b68b3cba4/61f211475b57f325c91372b4/5-LCVservice.jpg',
    alt: 'isuzu warranty',
  },
  // showPanel: true,
};

// export const MENU_LCV_SERVICE_WARRANTY: IMenu = {
//   label: 'การรับประกัน',
//   url: '/service/warranty',
//   image: {
//     src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltf415e48b68b3cba4/61f211475b57f325c91372b4/5-LCVservice.jpg',
//     alt: 'isuzu warranty',
//   },
//   // showPanel: true,
// };

export const MENU_LCV_CALCULATOR_FOOTER: IMenu = {
  label: 'ຄໍານວນຄ່າງວດ',
  url: MENU_LCV_CALCULATOR.url,
};

export const MENU_LCV_REGISTER_FOOTER: IMenu = {
  label: 'ທົດລອງຂັບ',
  url: MENU_LCV_REGISTER.url,
};

export const MENU_LCV_DOWNLOAD_BROCHURE_FOOTER: IMenu = {
  label: 'ດາວໂຫຼດໂບຊົວ',
  url: MENU_LCV_DOWNLOAD_BROCHURE.url,
};

export const MENU_LCV_ABOUT_CONTACT_FOOTER: IMenu = {
  label: MENU_LCV_ABOUT_CONTACT.label,
  url: MENU_LCV_ABOUT_CONTACT.url,
};

export const MENU_LCV_TEST_DRIVE_FOOTER: IMenu = {
  label: 'ສົນໃຈລົດອີຊູຊຸ',
  url: '',
  submenu: [
    MENU_LCV_REGISTER_FOOTER,
    MENU_LCV_CALCULATOR_FOOTER,
    MENU_LCV_DOWNLOAD_BROCHURE_FOOTER,
    MENU_LCV_SERVICE_CENTER,
    MENU_LCV_ABOUT_CONTACT_FOOTER,
  ],
};

// export const MENU_LCV_SERVICE: IMenu = {
//   label: 'บริการ',
//   url: '',
//   submenu: [
//     MENU_LCV_SERVICE_CENTER,
//     MENU_LCV_SERVICE_MAINTENANCE_LIST,
//     MENU_LCV_SERVICE_PARTS,
//     // MENU_LCV_SERVICE_WARRANTY,
//     MENU_LCV_SERVICE_ONWER_MANUAL,
//     MENU_LCV_SERVICE_CV,
//   ],
// };

// LCV ABOUT
export const MENU_LCV_ABOUT_HISTORY: IMenu = {
  label: 'ประวัติอีซูซุ',
  url: '/about-history',
  showPanel: true,
};

export const MENU_LCV_ABOUT_NEWS: IMenu = {
  label: 'ข่าวสารและกิจกรรม',
  url: '/news-activities',
  showPanel: true,
};

export const MENU_LCV_ABOUT_CAREER: IMenu = {
  label: 'สมัครงาน',
  url: 'https://career.tripetchgroup.com',
  target: '_blank',
  showPanel: true,
};

export const MENU_LCV_ABOUT_PRIVACY_POLICY: IMenu = {
  label: 'นโยบายความเป็นส่วนตัว',
  url: '/privacy-policy',
};

export const MENU_LCV_ABOUT_FAQ: IMenu = {
  label: 'คำถามที่พบบ่อย',
  url: '/faqs',
  showPanel: true,
};

export const MENU_LCV_ABOUT: IMenu = {
  label: 'เกี่ยวกับอีซูซุ',
  url: '',
  submenu: [MENU_LCV_ABOUT_CAREER, MENU_LCV_ABOUT_PRIVACY_POLICY],
};

// LCV SOCIAL
export const MENU_LCV_SOCIAL_FACEBOOK_DMAX: IMenu = {
  label: 'Facebook Channel',
  url: 'https://www.facebook.com/isuzulaosofficial',
  target: '_blank',
  icon: 'facebook',
};

export const MENU_LCV_SOCIAL_YOUTUBE: IMenu = {
  label: 'Youtube Channel',
  url: 'https://www.youtube.com/channel/UC8FYWXlZcbUaGSDkxaEOz8A',
  target: '_blank',
  icon: 'youtube',
};

export const MENU_LCV_SOCIAL: IMenu = {
  label: 'Social Sharing',
  url: '',
  submenu: [MENU_LCV_SOCIAL_FACEBOOK_DMAX, MENU_LCV_SOCIAL_YOUTUBE],
};

export const MENU_LCV_TRI_PETCH_GROUP: IMenu = {
  label: 'test',
  url: 'https://www.tripetchgroup.com',
  target: '_blank',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt3c3a5762e667c495/6568536a94a247780134d316/tripetchgroup-logo.svg',
    alt: 'contact tripetchgroup',
    width: 207,
    height: 62,
  },
};

export const MENU_LCV_ISUZU_HOTLINE: IMenu = {
  label: '',
  url: 'tel:021180777',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt33f0eff0eb661808/621dd2525662980e56b3b7c3/logo-isuzu-hotline.svg',
    alt: 'ISUZU HOTLINE สายด่วน',
    width: 200,
    height: 76,
  },
};

export const MENU_LCV_CONTACT: IMenu = {
  label: 'ช่องทางการติดต่อ',
  url: '',
  submenu: [MENU_LCV_TRI_PETCH_GROUP],
};

export const MENU_LCV_PRIMARY_NAV: IMenu[] = [
  MENU_LCV_MODEL_HEADER,
  MENU_LCV_CALCULATOR,
  MENU_LCV_DOWNLOAD_BROCHURE,
  MENU_LCV_SERVICE_CENTER,
  MENU_LCV_ABOUT_CONTACT,
];

export const COPYRIGHT = `Copyright © 2024 Tri Petch ISUZU Sales`;

export const REMARK = `ລຸ້ນ ສີ ແລະ ອັອບຊັ່ນທີ່ວາງຈຳໜ່າຍ <span>ອາດແຕກຕ່າງກັນໃນແຕ່ລະປະເທດ</span> <span>ລົດ​ທີ່​ຈຳ​ໜ່າຍ​ໃນສ​ປ​ປ ລາວ </span>​ພວງ​ມະ​ໄລຈະ​ຢູ່​ດ້ານ​ຊ້າຍ​ມື`;

export const BUTTON_LCV_FIND_SERVICE_CENTER: IButton = {
  label: 'ค้นหาศูนย์บริการ',
  url: MENU_LCV_DEALER.url + '?service=1&vehicle=lcv',
  target: MENU_LCV_DEALER.target,
  icon: MENU_LCV_DEALER.icon,
};

export const BUTTON_LCV_FIND_SERVICE_CENTER_BORDER: IButton = {
  label: 'ค้นหาศูนย์บริการ',
  url: MENU_LCV_DEALER.url + '?service=1&vehicle=lcv',
  target: MENU_LCV_DEALER.target,
  icon: MENU_LCV_DEALER.icon,
  type: 'border',
};

export const BUTTON_LCV_REGISTER: IButton = {
  label: 'ສົນໃຈລົດ',
  url: MENU_LCV_REGISTER.url,
  target: MENU_LCV_REGISTER.target,
  icon: MENU_LCV_REGISTER.icon,
};

export const BUTTON_LCV_SERVICE_CENTER_STANDARD: IButton = {
  label: MENU_LCV_ABOUT_CONTACT.label,
  url: MENU_LCV_ABOUT_CONTACT.url,
  target: MENU_LCV_DEALER.target,
  icon: MENU_LCV_DEALER.icon,
};
