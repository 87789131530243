const CarLCV: React.FC = () => (
  <svg width="62" height="30">
    <use
      href="/imgs/icons/common.svg#car-lcv"
      xlinkHref="/imgs/icons/common.svg#car-lcv"
    />
  </svg>
);
CarLCV.displayName = 'SharedElementIconsCarLCV';
export default CarLCV;
